import {Any, JsonObject, JsonProperty, PropertyConvertingMode} from "json2typescript";


@JsonObject("SocialNetworkDetailSociete")
export default class SocialNetworkDetailSociete {

	@JsonProperty("url", String)
	url: string = ''

	@JsonProperty("value", String)
	value: string = ''


    @JsonProperty("isReadable", Boolean)
    isReadable: boolean = undefined;

    @JsonProperty("isUpdatable", Boolean)
    isUpdatable: boolean = undefined;

    @JsonProperty("isDeletable", Boolean)
    isDeletable: boolean = undefined;
}
