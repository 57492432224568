import fetcher from "./useFetcher";
import useJsonConverter from "./useJsonConverter";
import {AuthToken} from "../../Entity";
import moment from "moment/moment";
import useFetcher from "./useFetcher";
import lktApi from "./useApi";

const TokenKey = 'lkt-authtoken'
const SwitchUserKey = 'app-switchuser'
const TokenExpireAtKey = 'app-token-expireat';

const UseAuth = () => {
    const login =  async (payload: {username:string; password: string;}) => {
        const json = await lktApi.post('login', payload);
        const authResponse= useJsonConverter.deserializeObject<AuthToken>(json, AuthToken)
        setToken(authResponse);
        return authResponse;
    }

    const refreshToken = async ()=>{
        const json = await lktApi.get('refreshtoken');

    }
    const isRefreshTokenRequired = (fromUrl: string) => {
        if(fromUrl?.match(/refreshtoken|login/)) {
            return false;
        }
        const authToken =  getToken();
        if( !authToken ) {
            return
        }
        const tokenExipreAt = authToken.expireAt;
        if(!tokenExipreAt) {
            return false;
        }
        const now = moment();
        const diff = tokenExipreAt.diff(now,'seconds');
        return ( diff > 0 && diff < 1800);
    }

    const refreshTokenIfRequired =  async (fromUrl: string) => {
        if( !isRefreshTokenRequired(fromUrl)) {
            return;
        }
        const autToken = await lktApi.get<AuthToken>('refreshtoken');
        setToken(autToken);
    }

    const isExpiredToken = (authToken: AuthToken) => {
        const now = moment();
        const diff = authToken.expireAt.diff(now,'seconds');
        return diff < 0;
    }

    const getToken = ()=> {
        const json = localStorage.getItem(TokenKey)
        if(!json) {
            return null;
        }
        try {
            const authToken = useJsonConverter.deserializeObject(JSON.parse(json), AuthToken)
            if( isExpiredToken(authToken)) {
                console.error("Expired token");
                removeToken();
                return null;
            }
            return authToken;
        }catch (e) {
            console.error(e);
            return null;
        }
    }

    const setToken = (authToken: AuthToken) => {
        localStorage.setItem(TokenKey, JSON.stringify(authToken));
    }

    const removeToken = ()=>{
        localStorage.removeItem(TokenKey);
    }

    return {login, getToken, setToken, removeToken, refreshTokenIfRequired}

}

/*
export function setTokenExpireAt(moment:Moment){
    localStorage.setItem(TokenExpireAtKey, moment.format())
}

export function getTokentExpireAt(){
    const dateString: string|null = localStorage.getItem(TokenExpireAtKey);
    //@ts-ignore
    return moment(dateString);
}

export function removeToken() {
    localStorage.removeItem(TokenExpireAtKey);
    return localStorage.removeItem(TokenKey)
}

export function getSwitchUser(): string|null {
    return localStorage.getItem(SwitchUserKey)
}

export function setSwitchUser(email: string) {
    return localStorage.setItem(SwitchUserKey, email)
}

export function removeSwitchUser() {
    return localStorage.removeItem(SwitchUserKey)
}

 */


const useAuth =  UseAuth()
export default useAuth;
