<script lang="ts">
import { Vue, Component, Watch, toNative, Emit } from "vue-facing-decorator";
import { usePermissionsStore } from "@/store/PermissionsStore";
import router from "@/router/router";
@Component({
  methods: { usePermissionsStore },
})
class NavbarSub extends Vue {
  mobileMenu: boolean = false;

  usePermissionsStore = usePermissionsStore;

  @Emit("toggle-mobile-menu")
  toggleMenuMobile() {
    this.mobileMenu = !this.mobileMenu;
    return this.mobileMenu;
  }
  setActiveLink(index) {
    usePermissionsStore().setNavActive(index);
  }
}

let component = NavbarSub;
(function () {
  component = toNative(component);
})();
export default component;
</script>

<template>
  <div class="bg-gray-200">
    <div class="container-xl py-2 navbar-sub">
      <ul class="w-100 d-none d-sm-flex mb-0 p-0">
        <li
          v-for="(link, index) in usePermissionsStore().subMenuRoutes"
          class="border-bottom border-gray-300 position-relative"
        >
          <router-link
            @click="setActiveLink(index)"
            :class="'d-flex justify-content-center nav-link text-nowrap w-100 text-center align-middle mb-0 py-2 h-100 justify-content-center text-secondary text-capitalize border-b nav-link position-relative'"
            :to="link.path"
          >
            <span
              :style="[
                0 === index
                  ? `left: ${usePermissionsStore().navActive}00%`
                  : '',
              ]"
              :class="[0 === index ? 'active-link' : '']"
            ></span>
            <div class="d-flex">
              <i
                :class="
                  'align-self-center d-block d-md-inline-block mx-auto text-secondary icon-w-md-4 icon-h-md-4 icon-w-xl-6 icon-h-xl-6 me-md-2 lkt-icon-' +
                  [link.meta.icon ? link.meta.icon : '']
                "
              ></i>
              <span>{{ $t("name." + link.name) }}</span>
            </div>
          </router-link>
        </li>
      </ul>
      <div class="cursor-pointer hamburger-nav d-flex d-sm-none">
        <i
          @click="toggleMenuMobile"
          :class="`ms-auto text-secondary text-3xl ${mobileMenu ? 'bi-x-lg' : 'bi-list'}`"
        ></i>
      </div>
      <!--   MOBILE MENU   -->
      <div
        :class="`mobile-menu d-flex flex-column gap-2 ${mobileMenu ? 'mobile-menu-open' : ''}`"
      >
        <div
          v-for="(link, index) in usePermissionsStore().subMenuRoutes"
          class="bg-gray-100 rounded-3 px-3"
        >
          <router-link
            @click="toggleMenuMobile"
            :class="'d-flex justify-content-start nav-link text-nowrap w-100 text-center align-middle mb-0 py-2 h-100 text-secondary fw-semibold text-capitalize border-b nav-link position-relative'"
            :to="link.path"
          >
            {{ $t("name." + link.name) }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
ul li {
  flex: 1 1 0px;
  list-style: none;
  font-size: 12px;
  font-weight: 500;
}

.active-link {
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 3px;
  left: 0;
  background-color: #da1d52;
  bottom: -2px;
  transition: all 0.3s;
}

.mobile-menu {
  height: 0px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.mobile-menu-open {
  height: 90vh;
  transition: all 0.3s ease-in-out;
}

@media screen and (min-width: 576px) {
  .navbar-sub {
  }

  .nav-link {
    font-size: 13px;
  }
}

@media screen and (min-width: 768px) {
  ul li {
    font-size: 14px;
  }

  .nav-link {
    font-size: 16px;
  }
}

@media screen and (min-width: 992px) {
  ul li {
    font-size: 16px;
  }

  .nav-link {
    font-size: 18px;
  }
}

@media screen and (min-width: 1200px) {
  .nav-link {
    font-size: 22px;
  }
}
</style>
