import Axios, {AxiosHeaders, AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig} from "axios";
import useAuth from "./useAuth";
import moment from "moment";
import {AuthToken} from "../../Entity";
const UseFetcher = () => {

    let endpoint: string = '';
    const fetcher = Axios.create({});

    fetcher.interceptors.response.use(
        (response:AxiosResponse)=>{
            const statusCode = response.status;
            if( statusCode >=200 && statusCode < 300) {
                return response;
            }
            return Promise.reject("Unknown error: " + response.data)
        },
        (err: any) => {
            const response = err.response;
            const data = response.data??={};
            let error:any = {
                code: response.status,
                error: response.data?.error??response.data?.message??response.statusText,
                data: data
            }
            console.error("lkt api error: ", error);
            return Promise.reject(error);
        });

    fetcher.interceptors.request.use( (requestConfig:InternalAxiosRequestConfig)=>{
        const headers = ajaxHeaders();
        for(const key of Object.keys(headers)) {
            requestConfig.headers[key]=headers[key];
        }
        return requestConfig;
    },(err: any) => {
        Promise.reject(err)
    });

    const apiContext = (userApiContext?: Object) => {
        let defaultContext =  {
            groups: ['api']
        }
        let context = defaultContext;

        if( userApiContext ) {
            context = {...defaultContext, ...userApiContext}
        }
        return context;
    }
    const ajaxHeaders = ()=>{
        const headers= {
            'X-Requested-With': 'XMLHttpRequest',
            'x-api': 'api',
            'Content-Type': 'application/json',
        };
        const authToken = useAuth.getToken();
        if( authToken ) {
            headers['Authorization'] = 'bearer ' + authToken.token
        }

        /*const switchUserEmail = getSwitchUser();
        if( switchUserEmail ){
            headers['X-Switch-User'] = switchUserEmail
        }*/
        return headers;
    }

    const setEndPoint = (newEndPoint: string) => {
        endpoint = newEndPoint;
    }

    const request = async <T>(config:AxiosRequestConfig, userApiContext?: Object):Promise<T> => {
        await useAuth.refreshTokenIfRequired(config.url);

        if( apiContext ) {
            config.headers = {};
            config.headers['api-context']= JSON.stringify(apiContext(userApiContext));
        }
        const requestConfig: AxiosRequestConfig = Object.assign({methode: 'get'},config);
        requestConfig.url =  endpoint + requestConfig.url;
        return (await fetcher.request(requestConfig)).data;
    }

    return {setEndPoint, request}
}

const useFetcher =  UseFetcher();
export default useFetcher;
