import { defineStore } from "pinia";
import { FormLoginEty } from "@/typing/formLoginEty";
import { lktApi } from "@lkt/core";
import { Consultant } from "@lkt/core";
import { usePermissionsStore } from "@/store/PermissionsStore";
import router, { resetRouter } from "@/router/router";

import * as constants from "node:constants";

export const useUserStore = defineStore("userStore", {
  // other options...

  state: () => {
    return {
      id: 0,
      username: "",
      email: "",
      language: "",
      avatar: "",
    };
  },
  actions: {
    // User Token exists, but no info in store (refresh browser page) => reload user from api
    async fetchMe() {
      //@mathieu, example pour passer le context de serialization au BO
      const consultant = await lktApi.get<Consultant>("me", {
        groups: ["api", "consult.cabinet"],
      });
      this.$patch((state) => {
        state.id = consultant.idConsult;
        state.username = consultant.nom;
        state.language = consultant.language;
      });

      const permissionsStore = usePermissionsStore();
      const roles = consultant.admin ? ["ADMIN"] : ["EDITOR"];
      permissionsStore.setRoles(roles);
    },

    isLogged() {
      return lktApi.isLoggedIn();
    },

    hasInfoStored() {
      return !!this.$state.id;
    },

    async login(payload: FormLoginEty) {
      await lktApi.login({
        username: payload.login as string,
        password: payload.password as string,
      });
      await this.fetchMe();
    },

    logout() {
      const permissionsStore = usePermissionsStore();
      permissionsStore.clearRoutes();
      permissionsStore.clearRoles();
      this.$reset();
      lktApi.logout();
      resetRouter();
    },
  },
});
