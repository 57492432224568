import {Any, JsonObject, JsonProperty, PropertyConvertingMode} from "json2typescript";

import Cabinet from "./Cabinet";
import TagGlobal from "./TagGlobal";
import TagProduct from "./TagProduct";
import Consultant from "./Consultant";
import TypeAction from "./TypeAction";
import JobReference from "./JobReference";

@JsonObject("Candidat")
export default class Candidat {

	@JsonProperty("idCandidat", Number)
	idCandidat: number = undefined

	@JsonProperty("dateCreation", String)
	dateCreation: string = ''

	@JsonProperty("dateModification", String)
	dateModification: string = ''

	@JsonProperty("dateMajMensuelle", String)
	dateMajMensuelle: string = ''

	@JsonProperty("prenom", String)
	prenom: string = ''

	@JsonProperty("nom", String)
	nom: string = ''

	@JsonProperty("nomPrenom", String)
	nomPrenom: string = ''

	@JsonProperty("adresse", String)
	adresse: string = ''

	@JsonProperty("codePostal", String)
	codePostal: string = ''

	@JsonProperty("ville", String)
	ville: string = ''

	@JsonProperty("geocoderFound", Boolean)
	geocoderFound: boolean = undefined

	@JsonProperty("telPortable", String)
	telPortable: string = ''

	@JsonProperty("email", String)
	email: string = ''

	@JsonProperty("remuneration", Number)
	remuneration: number = undefined

	@JsonProperty("remunerationFixe", Number)
	remunerationFixe: number = undefined

	@JsonProperty("declarationSociale", String)
	declarationSociale: string = ''

	@JsonProperty("deplacement", String)
	deplacement: string = ''

	@JsonProperty("vehicule", String)
	vehicule: string = ''

	@JsonProperty("cabinetExter", String)
	cabinetExter: string = ''

	@JsonProperty("information", String)
	information: string = ''

	@JsonProperty("parcours", String)
	parcours: string = ''

	@JsonProperty("presentation", String)
	presentation: string = ''

	@JsonProperty("referenceEmployeur", String)
	referenceEmployeur: string = ''

	@JsonProperty("analyze", String)
	analyze: string = ''

	@JsonProperty("hasCompte", Boolean)
	hasCompte: boolean = undefined

	@JsonProperty("cabinet", Cabinet)
	cabinet: Cabinet = undefined

	@JsonProperty("lifeCycle", String)
	lifeCycle: string = ''

	@JsonProperty("skills", String)
	skills: string = ''

	@JsonProperty("cvIntegral", String)
	cvIntegral: string = ''

	@JsonProperty("typeContrats", [String])
	typeContrats: string[] = undefined

	@JsonProperty("situation", String)
	situation: string = ''

	@JsonProperty("contributorWay", String)
	contributorWay: string = ''

	@JsonProperty("experienceCabinet", TagGlobal, PropertyConvertingMode.PASS_NULLABLE)
	experienceCabinet?: TagGlobal = undefined

	@JsonProperty("familleMetier", TagProduct, PropertyConvertingMode.PASS_NULLABLE)
	familleMetier?: TagProduct = undefined

	@JsonProperty("experience", TagGlobal, PropertyConvertingMode.PASS_NULLABLE)
	experience?: TagGlobal = undefined

	@JsonProperty("niveauFormation", TagGlobal, PropertyConvertingMode.PASS_NULLABLE)
	niveauFormation?: TagGlobal = undefined

	@JsonProperty("source", TagGlobal, PropertyConvertingMode.PASS_NULLABLE)
	source?: TagGlobal = undefined

	@JsonProperty("stabilite", TagGlobal, PropertyConvertingMode.PASS_NULLABLE)
	stabilite?: TagGlobal = undefined

	@JsonProperty("encadrement", TagGlobal, PropertyConvertingMode.PASS_NULLABLE)
	encadrement?: TagGlobal = undefined

	@JsonProperty("manager", Boolean)
	manager: boolean = undefined

	@JsonProperty("nightOut", TagGlobal, PropertyConvertingMode.PASS_NULLABLE)
	nightOut?: TagGlobal = undefined

	@JsonProperty("preavis", TagProduct, PropertyConvertingMode.PASS_NULLABLE)
	preavis?: TagProduct = undefined

	@JsonProperty("typeSociete", TagProduct, PropertyConvertingMode.PASS_NULLABLE)
	typeSociete?: TagProduct = undefined

	@JsonProperty("creator", Consultant, PropertyConvertingMode.PASS_NULLABLE)
	creator?: Consultant = undefined

	@JsonProperty("contributor", Consultant, PropertyConvertingMode.PASS_NULLABLE)
	contributor?: Consultant = undefined

	@JsonProperty("preavisNegociable", String)
	preavisNegociable: string = ''

	@JsonProperty("astreinte", String)
	astreinte: string = ''

	@JsonProperty("nocturne", String)
	nocturne: string = ''

	@JsonProperty("tempsPartiel", String)
	tempsPartiel: string = ''

	@JsonProperty("highLastAction", TypeAction)
	highLastAction: TypeAction = undefined

	@JsonProperty("highLastActionAt", String)
	highLastActionAt: string = ''

	@JsonProperty("missingItems", [String])
	missingItems: string[] = undefined

	@JsonProperty("teletravail", TagGlobal, PropertyConvertingMode.PASS_NULLABLE)
	teletravail?: TagGlobal = undefined

	@JsonProperty("jobReferences", [JobReference], PropertyConvertingMode.PASS_NULLABLE)
	jobReferences?: JobReference[] = undefined

	@JsonProperty("genre", String)
	readonly genre: string = ''

	@JsonProperty("identity", String)
	readonly identity: string = ''

	@JsonProperty("logiciels", [Any], PropertyConvertingMode.PASS_NULLABLE)
	readonly logiciels?: any[] = undefined

	@JsonProperty("languageLevels", [Any], PropertyConvertingMode.PASS_NULLABLE)
	readonly languageLevels?: any[] = undefined

	@JsonProperty("latitude", Number, PropertyConvertingMode.PASS_NULLABLE)
	readonly latitude?: number = undefined

	@JsonProperty("longitude", Number, PropertyConvertingMode.PASS_NULLABLE)
	readonly longitude?: number = undefined


    @JsonProperty("isReadable", Boolean)
    isReadable: boolean = undefined;

    @JsonProperty("isUpdatable", Boolean)
    isUpdatable: boolean = undefined;

    @JsonProperty("isDeletable", Boolean)
    isDeletable: boolean = undefined;
}
