import {Any, JsonObject, JsonProperty, PropertyConvertingMode} from "json2typescript";

import MissionContact from "./MissionContact";
import Cabinet from "./Cabinet";
import Consultant from "./Consultant";
import TagProduct from "./TagProduct";
import Societe from "./Societe";
import AbstractTag from "./AbstractTag";
import TagGlobal from "./TagGlobal";
import TypeAction from "./TypeAction";

@JsonObject("Mission")
export default class Mission {

	@JsonProperty("idMission", Number)
	idMission: number = undefined

	@JsonProperty("intitule", String)
	intitule: string = ''

	@JsonProperty("remunerationMini", Number)
	remunerationMini: number = undefined

	@JsonProperty("remunerationMaxi", Number)
	remunerationMaxi: number = undefined

	@JsonProperty("remunerationVariable", Number)
	remunerationVariable: number = undefined

	@JsonProperty("ville", String)
	ville: string = ''

	@JsonProperty("codePostal", String)
	codePostal: string = ''

	@JsonProperty("geocoderFound", Boolean)
	geocoderFound: boolean = undefined

	@JsonProperty("missionContacts", [MissionContact], PropertyConvertingMode.PASS_NULLABLE)
	missionContacts?: MissionContact[] = undefined

	@JsonProperty("dateCreation", String)
	dateCreation: string = ''

	@JsonProperty("adresse", String)
	adresse: string = ''

	@JsonProperty("cabinet", Cabinet)
	cabinet: Cabinet = undefined

	@JsonProperty("consultantFollowers", [Consultant], PropertyConvertingMode.PASS_NULLABLE)
	consultantFollowers?: Consultant[] = undefined

	@JsonProperty("consultantOwner", Consultant, PropertyConvertingMode.PASS_NULLABLE)
	consultantOwner?: Consultant = undefined

	@JsonProperty("fonction", TagProduct)
	fonction: TagProduct = undefined

	@JsonProperty("societe", Societe, PropertyConvertingMode.PASS_NULLABLE)
	societe?: Societe = undefined

	@JsonProperty("status", String)
	status: string = ''

	@JsonProperty("typeContrat", String)
	typeContrat: string = ''

	@JsonProperty("notifiedCandidature", Boolean)
	notifiedCandidature: boolean = undefined

	@JsonProperty("exclusive", Boolean)
	exclusive: boolean = undefined

	@JsonProperty("tags", [AbstractTag], PropertyConvertingMode.PASS_NULLABLE)
	tags?: AbstractTag[] = undefined

	@JsonProperty("lastCandidatureAt", String)
	lastCandidatureAt: string = ''

	@JsonProperty("nbUnreadCv", Number)
	nbUnreadCv: number = undefined

	@JsonProperty("nbCandidature", Number)
	nbCandidature: number = undefined

	@JsonProperty("nbCandidatDraft", Number)
	nbCandidatDraft: number = undefined

	@JsonProperty("isPlace", Boolean)
	isPlace: boolean = undefined

	@JsonProperty("changeStatusDate", String)
	changeStatusDate: string = ''

	@JsonProperty("reasonStatus", TagGlobal)
	reasonStatus: TagGlobal = undefined

	@JsonProperty("lastCustomerAction", TypeAction)
	lastCustomerAction: TypeAction = undefined

	@JsonProperty("lastCustomerActionDate", String)
	lastCustomerActionDate: string = ''

	@JsonProperty("lastCandidatureAction", TypeAction)
	lastCandidatureAction: TypeAction = undefined

	@JsonProperty("lastCandidatureActionAt", String)
	lastCandidatureActionAt: string = ''

	@JsonProperty("label", String)
	readonly label: string = ''

	@JsonProperty("logiciels", [Any], PropertyConvertingMode.PASS_NULLABLE)
	readonly logiciels?: any[] = undefined

	@JsonProperty("missionCommissions", [Any], PropertyConvertingMode.PASS_NULLABLE)
	readonly missionCommissions?: any[] = undefined

	@JsonProperty("nbCandidatureShared", Number, PropertyConvertingMode.PASS_NULLABLE)
	readonly nbCandidatureShared?: number = undefined

	@JsonProperty("remunerationLabel", String)
	readonly remunerationLabel: string = ''

	@JsonProperty("latitude", Number, PropertyConvertingMode.PASS_NULLABLE)
	readonly latitude?: number = undefined

	@JsonProperty("longitude", Number, PropertyConvertingMode.PASS_NULLABLE)
	readonly longitude?: number = undefined


    @JsonProperty("isReadable", Boolean)
    isReadable: boolean = undefined;

    @JsonProperty("isUpdatable", Boolean)
    isUpdatable: boolean = undefined;

    @JsonProperty("isDeletable", Boolean)
    isDeletable: boolean = undefined;
}
