import {Any, JsonObject, JsonProperty, PropertyConvertingMode} from "json2typescript";

import Cabinet from "./Cabinet";

@JsonObject("Credential")
export default class Credential {

	@JsonProperty("idCredential", Number)
	idCredential: number = undefined

	@JsonProperty("login", String)
	login: string = ''

	@JsonProperty("password", String)
	password: string = ''

	@JsonProperty("actif", Boolean)
	actif: boolean = undefined

	@JsonProperty("cabinet", Cabinet)
	cabinet: Cabinet = undefined

	@JsonProperty("default", Boolean)
	default: boolean = undefined

}
