import { useMockStore } from "@/store/Mock/MockStore";
// Importation du fichier PDF depuis les assets
import mathildeCv from "@public/tmp/moisant_cv.pdf";

export const initMockStore = () => {
  const mockStore = useMockStore(); // Accès au store après initialisation de Pinia

  if (!mockStore.$state.missionMock.length) {
    mockStore.setMissions();
  }

  if (!mockStore.$state.candidatureMock.length) {
    mockStore.setCandidatures();
  }

  if (!mockStore.$state.cabinetMock.length) {
    mockStore.setCabinet();
  }

  if (!mockStore.$state.cvMock.length) {
    mockStore.setCv();
  }
};
