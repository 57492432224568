import {Any, JsonObject, JsonProperty, PropertyConvertingMode} from "json2typescript";

import PublicationProvider from "./PublicationProvider";
import Credential from "./Credential";
import AnnonceJobBoard from "./AnnonceJobBoard";

@JsonObject("AnnoncePublication")
export default class AnnoncePublication {

	@JsonProperty("idPublication", Number)
	idPublication: number = undefined

	@JsonProperty("provider", PublicationProvider, PropertyConvertingMode.PASS_NULLABLE)
	provider?: PublicationProvider = undefined

	@JsonProperty("dateCreation", String)
	dateCreation: string = ''

	@JsonProperty("dateModification", String)
	dateModification: string = ''

	@JsonProperty("status", String)
	status: string = ''

	@JsonProperty("errorMessage", String)
	errorMessage: string = ''

	@JsonProperty("credential", Credential, PropertyConvertingMode.PASS_NULLABLE)
	credential?: Credential = undefined

	@JsonProperty("internalReference", String)
	internalReference: string = ''

	@JsonProperty("parameters", Any)
	parameters: any = undefined

	@JsonProperty("jobBoards", [AnnonceJobBoard], PropertyConvertingMode.PASS_NULLABLE)
	jobBoards?: AnnonceJobBoard[] = undefined

}
