import {Any, JsonObject, JsonProperty, PropertyConvertingMode} from "json2typescript";

import Contact from "./Contact";

@JsonObject("AffaireContact")
export default class AffaireContact {

	@JsonProperty("status", String)
	status: string = ''

	@JsonProperty("contact", Contact, PropertyConvertingMode.PASS_NULLABLE)
	contact?: Contact = undefined

	@JsonProperty("creationDate", String)
	creationDate: string = ''

	@JsonProperty("action", String)
	action: string = ''


    @JsonProperty("isReadable", Boolean)
    isReadable: boolean = undefined;

    @JsonProperty("isUpdatable", Boolean)
    isUpdatable: boolean = undefined;

    @JsonProperty("isDeletable", Boolean)
    isDeletable: boolean = undefined;
}
