import {Any, JsonObject, JsonProperty, PropertyConvertingMode} from "json2typescript";

import Consultant from "./Consultant";
import CampaignMail from "./CampaignMail";
import EmailRecipient from "./EmailRecipient";
import TypeAction from "./TypeAction";
import DateTimeImmutable from "./DateTimeImmutable";
import Facturation from "./Facturation";
import AttachmentMail from "./AttachmentMail";

@JsonObject("Email")
export default class Email {

	@JsonProperty("idEmail", Number)
	idEmail: number = undefined

	@JsonProperty("consultantCreator", Consultant, PropertyConvertingMode.PASS_NULLABLE)
	consultantCreator?: Consultant = undefined

	@JsonProperty("createAt", String)
	createAt: string = ''

	@JsonProperty("subject", String)
	subject: string = ''

	@JsonProperty("body", String, PropertyConvertingMode.PASS_NULLABLE)
	body?: string = undefined

	@JsonProperty("campaignMail", CampaignMail, PropertyConvertingMode.PASS_NULLABLE)
	campaignMail?: CampaignMail = undefined

	@JsonProperty("originMail", String)
	originMail: string = ''

	@JsonProperty("originName", String)
	originName: string = ''

	@JsonProperty("destinationMail", String)
	destinationMail: string = ''

	@JsonProperty("destinationName", String)
	destinationName: string = ''

	@JsonProperty("copyRecipients", [EmailRecipient], PropertyConvertingMode.PASS_NULLABLE)
	copyRecipients?: EmailRecipient[] = undefined

	@JsonProperty("action", TypeAction, PropertyConvertingMode.PASS_NULLABLE)
	action?: TypeAction = undefined

	@JsonProperty("emailType", String)
	emailType: string = ''

	@JsonProperty("readAt", DateTimeImmutable)
	readAt: DateTimeImmutable = undefined

	@JsonProperty("sentAt", DateTimeImmutable)
	sentAt: DateTimeImmutable = undefined

	@JsonProperty("isRead", Boolean)
	isRead: boolean = undefined

	@JsonProperty("status", String)
	status: string = ''

	@JsonProperty("errorMsg", String)
	errorMsg: string = ''

	@JsonProperty("facturation", Facturation, PropertyConvertingMode.PASS_NULLABLE)
	facturation?: Facturation = undefined

	@JsonProperty("attachments", [AttachmentMail], PropertyConvertingMode.PASS_NULLABLE)
	attachments?: AttachmentMail[] = undefined

	@JsonProperty("actionLabel", String)
	readonly actionLabel: string = ''


    @JsonProperty("isReadable", Boolean)
    isReadable: boolean = undefined;

    @JsonProperty("isUpdatable", Boolean)
    isUpdatable: boolean = undefined;

    @JsonProperty("isDeletable", Boolean)
    isDeletable: boolean = undefined;
}
