import {Any, JsonObject, JsonProperty, PropertyConvertingMode} from "json2typescript";

import TagGlobal from "./TagGlobal";
import Cabinet from "./Cabinet";
import Consultant from "./Consultant";
import Mission from "./Mission";
import Societe from "./Societe";
import Placement from "./Placement";

@JsonObject("Facturation")
export default class Facturation {

	@JsonProperty("idFacturation", Number)
	idFacturation: number = undefined

	@JsonProperty("codeFacture", String)
	codeFacture: string = ''

	@JsonProperty("status", String)
	status: string = ''

	@JsonProperty("datePlacement", String)
	datePlacement: string = ''

	@JsonProperty("honoraire", Number)
	honoraire: number = undefined

	@JsonProperty("prime", Number)
	prime: number = undefined

	@JsonProperty("dateDebutMission", String)
	dateDebutMission: string = ''

	@JsonProperty("dateFinMission", String)
	dateFinMission: string = ''

	@JsonProperty("dateFacturation", String)
	dateFacturation: string = ''

	@JsonProperty("dateEligible", String)
	dateEligible: string = ''

	@JsonProperty("dateRelanceInterne", String)
	dateRelanceInterne: string = ''

	@JsonProperty("dateReglement", String)
	dateReglement: string = ''

	@JsonProperty("dateReduction", String)
	dateReduction: string = ''

	@JsonProperty("nbJoursMission", Number)
	nbJoursMission: number = undefined

	@JsonProperty("stepContentieux", TagGlobal, PropertyConvertingMode.PASS_NULLABLE)
	stepContentieux?: TagGlobal = undefined

	@JsonProperty("commentaire", String)
	commentaire: string = ''

	@JsonProperty("lastItem", Boolean)
	lastItem: boolean = undefined

	@JsonProperty("cabinet", Cabinet)
	cabinet: Cabinet = undefined

	@JsonProperty("libelle", String)
	libelle: string = ''

	@JsonProperty("commissionValue", Number)
	commissionValue: number = undefined

	@JsonProperty("commissionReducValue", Number)
	commissionReducValue: number = undefined

	@JsonProperty("entiteFacturation", String)
	entiteFacturation: string = ''

	@JsonProperty("emailFacturation", [String])
	emailFacturation: string[] = undefined

	@JsonProperty("emailRelance", [String])
	emailRelance: string[] = undefined

	@JsonProperty("bonCommande", String)
	bonCommande: string = ''

	@JsonProperty("attentionFacturation", String)
	attentionFacturation: string = ''

	@JsonProperty("autreReference", String)
	autreReference: string = ''

	@JsonProperty("adresseFacturation", String)
	adresseFacturation: string = ''

	@JsonProperty("codePostalFacturation", String)
	codePostalFacturation: string = ''

	@JsonProperty("villeFacturation", String)
	villeFacturation: string = ''

	@JsonProperty("nbRelance", Number)
	nbRelance: number = undefined

	@JsonProperty("dateRelance", String)
	dateRelance: string = ''

	@JsonProperty("message", String)
	message: string = ''

	@JsonProperty("compteComptable", String)
	compteComptable: string = ''

	@JsonProperty("tvaCode", String)
	tvaCode: string = ''

	@JsonProperty("type", String)
	type: string = ''

	@JsonProperty("consult", Consultant, PropertyConvertingMode.PASS_NULLABLE)
	consult?: Consultant = undefined

	@JsonProperty("mission", Mission, PropertyConvertingMode.PASS_NULLABLE)
	mission?: Mission = undefined

	@JsonProperty("typeContrat", String)
	typeContrat: string = ''

	@JsonProperty("societe", Societe, PropertyConvertingMode.PASS_NULLABLE)
	societe?: Societe = undefined

	@JsonProperty("lastSuivi", String)
	lastSuivi: string = ''

	@JsonProperty("lastAction", String)
	lastAction: string = ''

	@JsonProperty("placement", Placement, PropertyConvertingMode.PASS_NULLABLE)
	placement?: Placement = undefined

	@JsonProperty("montantHt", Any)
	readonly montantHt: any = undefined

	@JsonProperty("montantTtc", Any)
	readonly montantTtc: any = undefined

	@JsonProperty("margeHt", Number)
	readonly margeHt: number = undefined


    @JsonProperty("isReadable", Boolean)
    isReadable: boolean = undefined;

    @JsonProperty("isUpdatable", Boolean)
    isUpdatable: boolean = undefined;

    @JsonProperty("isDeletable", Boolean)
    isDeletable: boolean = undefined;
}
