import {Any, JsonObject, JsonProperty, PropertyConvertingMode} from "json2typescript";

import Consultant from "./Consultant";
import TypeAction from "./TypeAction";
import Candidat from "./Candidat";
import Cabinet from "./Cabinet";

@JsonObject("CampaignMail")
export default class CampaignMail {

	@JsonProperty("idCampaign", Number)
	idCampaign: number = undefined

	@JsonProperty("consultant", Consultant, PropertyConvertingMode.PASS_NULLABLE)
	consultant?: Consultant = undefined

	@JsonProperty("createAt", String)
	createAt: string = ''

	@JsonProperty("subject", String)
	subject: string = ''

	@JsonProperty("action", TypeAction, PropertyConvertingMode.PASS_NULLABLE)
	action?: TypeAction = undefined

	@JsonProperty("commentaire", String)
	commentaire: string = ''

	@JsonProperty("candidat", Candidat, PropertyConvertingMode.PASS_NULLABLE)
	candidat?: Candidat = undefined

	@JsonProperty("cabinet", Cabinet)
	cabinet: Cabinet = undefined

	@JsonProperty("terminated", Boolean)
	terminated: boolean = undefined

	@JsonProperty("archived", Boolean)
	archived: boolean = undefined

	@JsonProperty("paused", Boolean)
	paused: boolean = undefined

	@JsonProperty("priority", Number)
	priority: number = undefined

	@JsonProperty("nbMail", Number)
	nbMail: number = undefined

	@JsonProperty("nbMailRead", Number)
	nbMailRead: number = undefined

	@JsonProperty("nbMailError", Number)
	nbMailError: number = undefined


    @JsonProperty("isReadable", Boolean)
    isReadable: boolean = undefined;

    @JsonProperty("isUpdatable", Boolean)
    isUpdatable: boolean = undefined;

    @JsonProperty("isDeletable", Boolean)
    isDeletable: boolean = undefined;
}
