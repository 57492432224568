<script lang="ts">
import { Vue, Component, toNative } from "vue-facing-decorator";

@Component({
  components: {
    Navbar: () => import("@/components/layouts/navigation/Navbar.vue"),
    Footer: () => import("@/components/layouts/navigation/Footer.vue"),
    MobileFooter: () =>
      import("@/components/layouts/navigation/MobileFooter.vue"),
  },
})
class AuthenticatedLayout extends Vue {
  windowTop: number = 0;
  hideNav: boolean = false;
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  }
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  }

  onScroll(e) {
      if( window.top ) {
          this.hideNav =
              window.top.scrollY > 92 && window.top.scrollY >= this.windowTop;
          this.windowTop = window.top.scrollY;
      }
  }
}
let component = AuthenticatedLayout;
(function () {
  component = toNative(component);
})();
export default component;
</script>

<template>
  <div :class="`navbar-wrap ${hideNav ? 'hide-nav' : ''}`">
    <Navbar></Navbar>
  </div>
  <div class="content-wrap">
    <router-view />
  </div>
  <Footer :class="'d-none d-md-flex'"></Footer>
  <MobileFooter :class="'d-md-none'"></MobileFooter>
</template>

<style></style>
