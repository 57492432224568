import {Any, JsonObject, JsonProperty, PropertyConvertingMode} from "json2typescript";

import Consultant from "./Consultant";
import Cabinet from "./Cabinet";
import TypeAction from "./TypeAction";
import Groupe from "./Groupe";

@JsonObject("AbstractAdChase")
export default class AbstractAdChase {

	@JsonProperty("idAdChase", Number)
	idAdChase: number = undefined

	@JsonProperty("intitule", String)
	intitule: string = ''

	@JsonProperty("consultManager", Consultant, PropertyConvertingMode.PASS_NULLABLE)
	consultManager?: Consultant = undefined

	@JsonProperty("consultCreator", Consultant, PropertyConvertingMode.PASS_NULLABLE)
	consultCreator?: Consultant = undefined

	@JsonProperty("dateCreation", String)
	dateCreation: string = ''

	@JsonProperty("dateModification", String)
	dateModification: string = ''

	@JsonProperty("cabinet", Cabinet)
	cabinet: Cabinet = undefined

	@JsonProperty("url", String)
	url: string = ''

	@JsonProperty("step", String)
	step: string = ''

	@JsonProperty("isArchived", Boolean)
	isArchived: boolean = undefined

	@JsonProperty("lockAffectUntil", String)
	lockAffectUntil: string = ''

	@JsonProperty("dateActualisation", String)
	dateActualisation: string = ''

	@JsonProperty("lastAction", TypeAction)
	lastAction: TypeAction = undefined

	@JsonProperty("withPotential", Boolean)
	withPotential: boolean = undefined

	@JsonProperty("reasonWithoutPotential", String)
	reasonWithoutPotential: string = ''

	@JsonProperty("remunerationMini", Number)
	remunerationMini: number = undefined

	@JsonProperty("remunerationMaxi", Number)
	remunerationMaxi: number = undefined

	@JsonProperty("ville", String)
	ville: string = ''

	@JsonProperty("codePostal", String)
	codePostal: string = ''

	@JsonProperty("groupe", Groupe, PropertyConvertingMode.PASS_NULLABLE)
	groupe?: Groupe = undefined

	@JsonProperty("description", String)
	description: string = ''

	@JsonProperty("isUnlocked", Boolean)
	readonly isUnlocked: boolean = undefined

}
