import {Any, JsonObject, JsonProperty, PropertyConvertingMode} from "json2typescript";

import Candidature from "./Candidature";
import Consultant from "./Consultant";
import TypeAction from "./TypeAction";
import CommissionPlacement from "./CommissionPlacement";

@JsonObject("Placement")
export default class Placement {

	@JsonProperty("idPlacement", Number)
	idPlacement: number = undefined

	@JsonProperty("candidature", Candidature, PropertyConvertingMode.PASS_NULLABLE)
	candidature?: Candidature = undefined

	@JsonProperty("datePlacement", String)
	datePlacement: string = ''

	@JsonProperty("isCurrent", Boolean)
	isCurrent: boolean = undefined

	@JsonProperty("remuneration", Number)
	remuneration: number = undefined

	@JsonProperty("dateDebut", String)
	dateDebut: string = ''

	@JsonProperty("dateFin", String)
	dateFin: string = ''

	@JsonProperty("consultant", Consultant, PropertyConvertingMode.PASS_NULLABLE)
	consultant?: Consultant = undefined

	@JsonProperty("plManager", Consultant, PropertyConvertingMode.PASS_NULLABLE)
	plManager?: Consultant = undefined

	@JsonProperty("action", TypeAction)
	action: TypeAction = undefined

	@JsonProperty("cancelAction", TypeAction)
	cancelAction: TypeAction = undefined

	@JsonProperty("comment", String)
	comment: string = ''

	@JsonProperty("commissionValue", Number)
	commissionValue: number = undefined

	@JsonProperty("commissionReducValue", Number)
	commissionReducValue: number = undefined

	@JsonProperty("isReduction", Boolean)
	isReduction: boolean = undefined

	@JsonProperty("typeCommission", String)
	typeCommission: string = ''

	@JsonProperty("contributor", Consultant, PropertyConvertingMode.PASS_NULLABLE)
	contributor?: Consultant = undefined

	@JsonProperty("commissions", [CommissionPlacement], PropertyConvertingMode.PASS_NULLABLE)
	commissions?: CommissionPlacement[] = undefined


    @JsonProperty("isReadable", Boolean)
    isReadable: boolean = undefined;

    @JsonProperty("isUpdatable", Boolean)
    isUpdatable: boolean = undefined;

    @JsonProperty("isDeletable", Boolean)
    isDeletable: boolean = undefined;
}
