import {Any, JsonObject, JsonProperty, PropertyConvertingMode} from "json2typescript";


@JsonObject("ConsultantAvatar")
export default class ConsultantAvatar {

	@JsonProperty("id", Number)
	id: number = undefined

}
