import {Any, JsonObject, JsonProperty, PropertyConvertingMode} from "json2typescript";

import Fonction from "./Fonction";
import Consultant from "./Consultant";
import TagGlobal from "./TagGlobal";
import Societe from "./Societe";

@JsonObject("Contact")
export default class Contact {

	@JsonProperty("idContact", Number)
	idContact: number = undefined

	@JsonProperty("nom", String)
	nom: string = ''

	@JsonProperty("prenom", String)
	prenom: string = ''

	@JsonProperty("nomPrenom", String)
	nomPrenom: string = ''

	@JsonProperty("telephone", String)
	telephone: string = ''

	@JsonProperty("autreTelephone", String)
	autreTelephone: string = ''

	@JsonProperty("email", String)
	email: string = ''

	@JsonProperty("commentaire", String)
	commentaire: string = ''

	@JsonProperty("creationDate", String)
	creationDate: string = ''

	@JsonProperty("fonction", Fonction, PropertyConvertingMode.PASS_NULLABLE)
	fonction?: Fonction = undefined

	@JsonProperty("consult", Consultant, PropertyConvertingMode.PASS_NULLABLE)
	consult?: Consultant = undefined

	@JsonProperty("source", TagGlobal, PropertyConvertingMode.PASS_NULLABLE)
	source?: TagGlobal = undefined

	@JsonProperty("nbMissions", Number)
	nbMissions: number = undefined

	@JsonProperty("lastSignificativeCall", String)
	lastSignificativeCall: string = ''

	@JsonProperty("lastCallType", String)
	lastCallType: string = ''

	@JsonProperty("nbSignificativeCall", Number)
	nbSignificativeCall: number = undefined

	@JsonProperty("callQuality", String)
	callQuality: string = ''

	@JsonProperty("societes", [Societe], PropertyConvertingMode.PASS_NULLABLE)
	societes?: Societe[] = undefined

	@JsonProperty("emailStatus", String)
	emailStatus: string = ''

	@JsonProperty("lastEmailCheckAt", String)
	lastEmailCheckAt: string = ''

	@JsonProperty("smtpNameVerified", String)
	smtpNameVerified: string = ''

	@JsonProperty("nbMailRead", Number)
	nbMailRead: number = undefined

	@JsonProperty("lastMailRead", String)
	lastMailRead: string = ''

	@JsonProperty("societesLabels", Any, PropertyConvertingMode.PASS_NULLABLE)
	readonly societesLabels?: any = undefined

	@JsonProperty("bgColor", String)
	readonly bgColor: string = ''


    @JsonProperty("isReadable", Boolean)
    isReadable: boolean = undefined;

    @JsonProperty("isUpdatable", Boolean)
    isUpdatable: boolean = undefined;

    @JsonProperty("isDeletable", Boolean)
    isDeletable: boolean = undefined;
}
