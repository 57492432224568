import {Any, JsonObject, JsonProperty, PropertyConvertingMode} from "json2typescript";


@JsonObject("NafReference")
export default class NafReference {

	@JsonProperty("domain", String)
	domain: string = ''

	@JsonProperty("libelle", String)
	readonly libelle: string = ''

}
