<script lang="ts">
import { Vue, Component, toNative } from "vue-facing-decorator";

@Component({
  components: {
    Image: () => import("@/components/images/Image.vue"),
  },
})
class Footer extends Vue {}
let component = Footer;
(function () {
  component = toNative(component);
})();
export default component;
</script>

<template>
  <footer class="pt-7 pb-2">
    <div class="container">
      <div class="row">
        <div class="col-3">
          <Image :class="'lkt-logo'" :src="'lkt-logo.png'" />
          <article class="pt-5">
            <i
              class="lkt-icon-1fortheplanet icon-w-13 icon-h-7 text-gray-300"
            ></i>
            <p class="text-small col-lg-9 text-gray-400">
              {{ $t("footer.1percent") }}
            </p>
          </article>
        </div>

        <div class="col-6">
          <h6 class="text-secondary text-md fw-bold mb-3">
            {{ $t("title.ourContactDetails") }}
          </h6>
          <div class="text-gray-400">
            <p class="pb-0 mb-1">{{ $t("word.tel") }}: 01 80 88 61 02</p>
            <p class="pb-0 mb-1">
              {{ $t("word.mail") }}: contact@linkingtalents.fr
            </p>
            <p class="pb-0 mb-1">
              {{ $t("word.adress") }}: 78 avenue des Champs Élysées B562 - 75008
              Paris
            </p>
          </div>
        </div>

        <div class="col-3">
          <h6 class="text-secondary text-md text-md fw-bold mb-3">
            {{ $t("title.followUs") }}
          </h6>
          <a href="#">
            <i class="text-3xl bi-linkedin"></i>
          </a>
          <a href="#" class="mx-3">
            <i class="text-3xl bi-spotify"></i>
          </a>
          <a href="#">
            <i class="text-3xl bi-youtube"></i>
          </a>
        </div>
      </div>
      <p class="text-gray-400 text-sm text-center mb-0">
        © LinkingTalents.fr 2024 - {{ $t("footer.allRightsReserved") }}
      </p>
    </div>
  </footer>
</template>

<style scoped>
footer {
  width: 100%;
  background-image: url("@/assets/footer-bg.jpg");
  background-position: center;
  background-size: cover;
  position: absolute;
  bottom: 0;

  @media (min-width: 768px) {
    height: 420px;
  }
}

footer .lkt-logo {
  max-height: 65px;
}
</style>
