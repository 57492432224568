import {Any, JsonObject, JsonProperty, PropertyConvertingMode} from "json2typescript";

import NafReference from "./NafReference";
import TagGlobal from "./TagGlobal";
import DistanceSocieteMatrix from "./DistanceSocieteMatrix";
import SocialNetworkDetailSociete from "./SocialNetworkDetailSociete";
import TagProduct from "./TagProduct";

@JsonObject("Societe")
export default class Societe {

	@JsonProperty("idSociete", Number)
	idSociete: number = undefined

	@JsonProperty("nom", String)
	nom: string = ''

	@JsonProperty("codePostal", String)
	codePostal: string = ''

	@JsonProperty("ville", String)
	ville: string = ''

	@JsonProperty("websiteUrl", String)
	websiteUrl: string = ''

	@JsonProperty("telephone", String)
	telephone: string = ''

	@JsonProperty("registerNumber", String)
	registerNumber: string = ''

	@JsonProperty("ca", Number)
	ca: number = undefined

	@JsonProperty("nafReference", NafReference, PropertyConvertingMode.PASS_NULLABLE)
	nafReference?: NafReference = undefined

	@JsonProperty("typeSociete", TagGlobal, PropertyConvertingMode.PASS_NULLABLE)
	typeSociete?: TagGlobal = undefined

	@JsonProperty("serviceSante", TagGlobal, PropertyConvertingMode.PASS_NULLABLE)
	serviceSante?: TagGlobal = undefined

	@JsonProperty("hasContact", Boolean)
	hasContact: boolean = undefined

	@JsonProperty("place", Boolean)
	place: boolean = undefined

	@JsonProperty("client", Boolean)
	client: boolean = undefined

	@JsonProperty("solvable", Boolean)
	solvable: boolean = undefined

	@JsonProperty("banni", Boolean)
	banni: boolean = undefined

	@JsonProperty("closed", Boolean)
	closed: boolean = undefined

	@JsonProperty("distanceMatrix", [DistanceSocieteMatrix], PropertyConvertingMode.PASS_NULLABLE)
	distanceMatrix?: DistanceSocieteMatrix[] = undefined

	@JsonProperty("socialNetworkDetails", [SocialNetworkDetailSociete], PropertyConvertingMode.PASS_NULLABLE)
	socialNetworkDetails?: SocialNetworkDetailSociete[] = undefined

	@JsonProperty("advertReportUrl", String)
	advertReportUrl: string = ''

	@JsonProperty("missionActive", Boolean)
	missionActive: boolean = undefined

	@JsonProperty("sansPotentiel", Boolean)
	sansPotentiel: boolean = undefined

	@JsonProperty("reasonWithoutPotential", String)
	reasonWithoutPotential: string = ''

	@JsonProperty("lockPpjUntil", String)
	lockPpjUntil: string = ''

	@JsonProperty("lockMissionPassiveUntil", String)
	lockMissionPassiveUntil: string = ''

	@JsonProperty("activite", TagProduct, PropertyConvertingMode.PASS_NULLABLE)
	activite?: TagProduct = undefined

	@JsonProperty("logiciels", Any, PropertyConvertingMode.PASS_NULLABLE)
	readonly logiciels?: any = undefined

	@JsonProperty("latitude", Number, PropertyConvertingMode.PASS_NULLABLE)
	readonly latitude?: number = undefined

	@JsonProperty("longitude", Number, PropertyConvertingMode.PASS_NULLABLE)
	readonly longitude?: number = undefined


    @JsonProperty("isReadable", Boolean)
    isReadable: boolean = undefined;

    @JsonProperty("isUpdatable", Boolean)
    isUpdatable: boolean = undefined;

    @JsonProperty("isDeletable", Boolean)
    isDeletable: boolean = undefined;
}
