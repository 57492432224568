import {Any, JsonObject, JsonProperty, PropertyConvertingMode} from "json2typescript";


@JsonObject("EmailRecipientDTO")
export default class EmailRecipientDTO {

	@JsonProperty("address", String)
	address: string = ''

	@JsonProperty("name", String)
	name: string = ''

	@JsonProperty("function", String)
	function: string = ''

}
