import fetcher from "./useFetcher";
import useAuth from "./useAuth";

export const LktApi =  ()=>{
    const defaultConfig = {
        endpoint: ''
    }
    let config = {...defaultConfig};
    fetcher.setEndPoint(config.endpoint);
    const setConfig = (payload: any) => {
        config = {...config, ...payload};
        fetcher.setEndPoint(config.endpoint);
    }

    const login =  async (payload: {username:string; password: string;}) => {
        return useAuth.login(payload);
    }
    const isLoggedIn = ()=> {
        return !!useAuth.getToken()
    }
    const logout =   () => {
        useAuth.removeToken();
    }

    const get = async <T>(url: string, apiContext?: Object)=>{
        return fetcher.request<T>({url: url, method: "get"}, apiContext)
    }

    const post = async <T>(url: string, data: Object = {}, apiContext?: Object)=>{
        return fetcher.request<T>({url: url, method: "post", data: data}, apiContext)
    }

    return {setConfig, login, logout, isLoggedIn, get, post}

}

const lktApi = LktApi();
export default lktApi;

