import router from "@/router/router";
import { useUserStore } from "@/store/UserStore";
import { usePermissionsStore } from "@/store/PermissionsStore";
import { RouteRecordRaw } from "vue-router";

const whiteList = ["/login", "/404", "/401", "/ui"]; // no redirect whitelist

/**
 * Dispatch user roles in store
 * Generate async routes according to user roles
 * Use a map() to unwrap the ref reactivity
 * @returns {Promise: <Array>}
 */
const dispatchPermissionStoreData = async () => {
  usePermissionsStore().setRoutes();
  const addRoutesPromises = usePermissionsStore().routes.map(
    (route: RouteRecordRaw) => {
      return new Promise<void>((resolve): void => {
        router.addRoute(route);
        resolve();
      });
    },
  );
  await Promise.all(addRoutesPromises);
};

router.afterEach(async (to: any, from, next: any) => {
  usePermissionsStore().setNavActive(router.currentRoute.value.meta.index);
});

router.beforeEach(async (to: any, from, next: any) => {
  // document.title = getPageTitle(to.meta.title);
  const loggedIn = useUserStore().isLogged();
  if (loggedIn) {
    if (useUserStore().hasInfoStored()) {
      return next();
    } else {
      try {
        await useUserStore().fetchMe();
        await dispatchPermissionStoreData();
        next({ ...to, replace: true });
      } catch (error) {
        alert(error);
        next(`/login?redirect=${to.path}`);
      }
    }

    // User logged in, but no info in store, fetch it from api
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next();
    } else {
      next(`/login?redirect=${to.path}`);
    }
  }
});

export { dispatchPermissionStoreData };
