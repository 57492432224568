import {Any, JsonObject, JsonProperty, PropertyConvertingMode} from "json2typescript";

import Contact from "./Contact";
import Consultant from "./Consultant";

@JsonObject("MissionContact")
export default class MissionContact {

	@JsonProperty("contact", Contact)
	contact: Contact = undefined

	@JsonProperty("clientShared", Boolean)
	clientShared: boolean = undefined

	@JsonProperty("consultantSharing", Consultant, PropertyConvertingMode.PASS_NULLABLE)
	consultantSharing?: Consultant = undefined


    @JsonProperty("isReadable", Boolean)
    isReadable: boolean = undefined;

    @JsonProperty("isUpdatable", Boolean)
    isUpdatable: boolean = undefined;

    @JsonProperty("isDeletable", Boolean)
    isDeletable: boolean = undefined;
}
