import {Any, JsonObject, JsonProperty, PropertyConvertingMode} from "json2typescript";

import Cabinet from "./Cabinet";
import Consultant from "./Consultant";

@JsonObject("Basket")
export default class Basket {

	@JsonProperty("idBasket", Number)
	idBasket: number = undefined

	@JsonProperty("name", String)
	name: string = ''

	@JsonProperty("type", String)
	type: string = ''

	@JsonProperty("cabinet", Cabinet)
	cabinet: Cabinet = undefined

	@JsonProperty("consultant", Consultant, PropertyConvertingMode.PASS_NULLABLE)
	consultant?: Consultant = undefined

	@JsonProperty("sharedWith", [Consultant], PropertyConvertingMode.PASS_NULLABLE)
	sharedWith?: Consultant[] = undefined

	@JsonProperty("ids", [String])
	ids: string[] = undefined

	@JsonProperty("createAt", String)
	createAt: string = ''

	@JsonProperty("updateAt", String)
	updateAt: string = ''


    @JsonProperty("isReadable", Boolean)
    isReadable: boolean = undefined;

    @JsonProperty("isUpdatable", Boolean)
    isUpdatable: boolean = undefined;

    @JsonProperty("isDeletable", Boolean)
    isDeletable: boolean = undefined;
}
