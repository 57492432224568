import {Any, JsonObject, JsonProperty, PropertyConvertingMode} from "json2typescript";


@JsonObject("AffaireBesoin")
export default class AffaireBesoin {

	@JsonProperty("id", Number)
	id: number = undefined

	@JsonProperty("intitule", String)
	intitule: string = ''

	@JsonProperty("remunerationMini", Number)
	remunerationMini: number = undefined

	@JsonProperty("remunerationMaxi", Number)
	remunerationMaxi: number = undefined

	@JsonProperty("codePostal", String)
	codePostal: string = ''

	@JsonProperty("ville", String)
	ville: string = ''

	@JsonProperty("status", String)
	status: string = ''

	@JsonProperty("createAt", String)
	createAt: string = ''

	@JsonProperty("statusUpdateAt", String)
	statusUpdateAt: string = ''


    @JsonProperty("isReadable", Boolean)
    isReadable: boolean = undefined;

    @JsonProperty("isUpdatable", Boolean)
    isUpdatable: boolean = undefined;

    @JsonProperty("isDeletable", Boolean)
    isDeletable: boolean = undefined;
}
