import {Any, JsonObject, JsonProperty, PropertyConvertingMode} from "json2typescript";


@JsonObject("TagGlobal")
export default class TagGlobal {

	@JsonProperty("idTag", Number)
	idTag: number = undefined

	@JsonProperty("value", String)
	value: string = ''

	@JsonProperty("fullValue", String, PropertyConvertingMode.PASS_NULLABLE)
	readonly fullValue?: string = undefined


    @JsonProperty("isReadable", Boolean)
    isReadable: boolean = undefined;

    @JsonProperty("isUpdatable", Boolean)
    isUpdatable: boolean = undefined;

    @JsonProperty("isDeletable", Boolean)
    isDeletable: boolean = undefined;
}
