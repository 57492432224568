import {Any, JsonObject, JsonProperty, PropertyConvertingMode} from "json2typescript";

import {Moment} from "moment";
import MomentConverter from "../../lib/MomentConverter"
@JsonObject("AuthToken")
export default class AuthToken {

	@JsonProperty("token", String)
	token: string = ''

	@JsonProperty("expireAt", MomentConverter)
	expireAt: Moment = undefined

	@JsonProperty("cabinets", [Any])
	cabinets: any[] = undefined

	@JsonProperty("mainCabinet", String)
	mainCabinet: string = ''

}
