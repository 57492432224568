import {Any, JsonObject, JsonProperty, PropertyConvertingMode} from "json2typescript";


@JsonObject("EmailRecipient")
export default class EmailRecipient {

	@JsonProperty("id", Number)
	id: number = undefined

	@JsonProperty("address", String)
	address: string = ''

	@JsonProperty("name", String)
	name: string = ''

	@JsonProperty("copyType", String)
	copyType: string = ''

}
