import {JsonConvert, OperationMode, ValueCheckingMode} from "json2typescript"

const UseJsonConverter = () => {
    const jsonConverter:JsonConvert = new JsonConvert();

    // jsonConverter.operationMode = OperationMode.LOGGING;
    jsonConverter.ignorePrimitiveChecks = false; // don't allow assigning number to string etc.

    // Si la variable attendue n'est pas défini ça va déclancher une exception
    jsonConverter.mapUndefinedToNull = false;
    // Si la variable n'est pas défini dans la réponse => error, si la variable vaut null, set to null, si
    jsonConverter.valueCheckingMode = ValueCheckingMode.ALLOW_NULL; // never allow null
    // Par défaut en MAP_NULLABLE si on le définit, alors on peut plus le modifier individuellement
    // serializer.propertyConvertingMode=PropertyConvertingMode.PASS_NULLABLE;

    jsonConverter.useDiscriminator = false; // enable the discriminator
    jsonConverter.discriminatorPropertyName = "$type"; // this is the property name
    //serializer.registerClasses(Contact); // register all classes
    return {jsonConverter}
}

const useJsonConverter = UseJsonConverter().jsonConverter;
export default useJsonConverter;
