import {Any, JsonObject, JsonProperty, PropertyConvertingMode} from "json2typescript";


@JsonObject("Cabinet")
export default class Cabinet {

	@JsonProperty("code", String)
	code: string = ''

	@JsonProperty("smsSignature", String)
	smsSignature: string = ''

	@JsonProperty("logoLocation", String)
	logoLocation: string = ''

}
