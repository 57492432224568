import {Any, JsonObject, JsonProperty, PropertyConvertingMode} from "json2typescript";


@JsonObject("Fonction")
export default class Fonction {

	@JsonProperty("idFonction", Number)
	idFonction: number = undefined

	@JsonProperty("label", String)
	label: string = ''

	@JsonProperty("pushVisibility", [String])
	pushVisibility: string[] = undefined

	@JsonProperty("default", Boolean)
	default: boolean = undefined


    @JsonProperty("isReadable", Boolean)
    isReadable: boolean = undefined;

    @JsonProperty("isUpdatable", Boolean)
    isUpdatable: boolean = undefined;

    @JsonProperty("isDeletable", Boolean)
    isDeletable: boolean = undefined;
}
