CandidacyDetails.vue
<script lang="ts">
import { Vue, Component, toNative, Watch } from "vue-facing-decorator";
import dayjs from "dayjs";

interface Message {
  sender: boolean;
  read: boolean | null;
  date: dayjs.Dayjs;
  content: string;
}

@Component({})
class Chat extends Vue {
  chatboxMessage: string = "Contacter";
  chatboxOpen: boolean = false;
  message: Message = {
    sender: true,
    read: null,
    date: dayjs(),
    content: "",
  };
  messages: Message[] = [
    {
      sender: true,
      read: null,
      date: dayjs("2024-04-13T15:25:00"),
      content:
        "Bonjour, je souhaiterais des infos complémentaires pour le candidat 75845.",
    },
    {
      sender: false,
      read: false,
      date: dayjs("2024-04-13T15:27:00"),
      content:
        "Bonjour ! Mais bien sûr ! 75845... ah, notre 'petit génie' en herbe. Que voulez-vous savoir ?",
    },
    {
      sender: true,
      read: null,
      date: dayjs("2024-04-13T15:28:00"),
      content:
        "On m’a dit qu’il est 'polyglotte', mais... en quel sens exactement ? Il connaît plusieurs langages de programmation, ou il parle couramment sept langues ?",
    },
    {
      sender: false,
      read: false,
      date: dayjs("2024-04-13T15:29:00"),
      content:
        "Ah, 'polyglotte' ! Disons qu’il maîtrise JavaScript, Python, C++, et… le langage des bugs bien sûr. Côté langues humaines, en revanche... c'est une autre histoire !",
    },
    {
      sender: true,
      read: null,
      date: dayjs("2024-04-13T15:30:00"),
      content:
        "D'accord... donc pas de risque de le voir discuter en mandarin avec nos clients chinois ?",
    },
    {
      sender: false,
      read: false,
      date: dayjs("2024-04-13T15:31:00"),
      content:
        "Seulement si 'console.log' est une salutation en mandarin ! Il est bien plus à l'aise avec des accolades qu’avec des salutations internationales, disons.",
    },
    {
      sender: true,
      read: null,
      date: dayjs("2024-04-13T15:32:00"),
      content:
        "Bon... Et côté compétences techniques, il fait du Node.js, non ?",
    },
    {
      sender: false,
      read: false,
      date: dayjs("2024-04-13T15:33:00"),
      content:
        "Node.js ? Absolument ! Et React, Angular... tout ce qu'il faut. Enfin, sauf ce qui est 'intuitif', bien sûr. Disons qu’il a une relation tumultueuse avec les frameworks front-end.",
    },
    {
      sender: true,
      read: null,
      date: dayjs("2024-04-13T15:34:00"),
      content: "Intéressant ! Alors il doit dormir aussi peu qu'il code, non ?",
    },
    {
      sender: false,
      read: false,
      date: dayjs("2024-04-13T15:35:00"),
      content:
        "S'il dort ? Eh bien, on pense qu'il se recharge en mettant sa chaise en 'standby'. L’inspiration peut frapper à toute heure !",
    },
    {
      sender: true,
      read: null,
      date: dayjs("2024-04-13T15:36:00"),
      content:
        "Tant qu'il ne se met pas à coder en COBOL... Vous ne me dites pas qu’il touche à ça aussi ?",
    },
    {
      sender: false,
      read: false,
      date: dayjs("2024-04-13T15:37:00"),
      content:
        "Oh, mais il a tenté, figurez-vous ! Nostalgie des années 60, peut-être. On dit même qu’il a ressuscité quelques lignes de BASIC pour le fun.",
    },
    {
      sender: true,
      read: null,
      date: dayjs("2024-04-13T15:38:00"),
      content:
        "Un vrai nostalgique du clavier, à ce que je vois ! Très bien, envoyez-moi ses coordonnées... Je sens qu'on va avoir des discussions hautes en couleur.",
    },
    {
      sender: false,
      read: false,
      date: dayjs("2024-04-13T15:39:00"),
      content:
        "Avec plaisir ! Préparez-vous pour un voyage technologique dans le temps !",
    },
  ];

  private chatTextArea!: HTMLTextAreaElement;
  private discussionWrapper!: HTMLElement;

  @Watch("messages.length")
  onMessage(newMessage) {
    if (newMessage) {
      this.$nextTick(() => {
        this.scrollToBottom();
      });
    }
  }

  @Watch("chatboxOpen")
  onOpening(opening) {
    if (opening) {
      this.$nextTick(() => {
        this.scrollToBottom();
        this.unReadMessage();
      });
    }
  }

  mounted() {
    this.chatTextArea = this.$refs.chatTextArea as HTMLTextAreaElement;
    this.chatTextArea.addEventListener("input", this.adjustTextAreaHeight);
    this.discussionWrapper = this.$refs.discussionWrapper as HTMLElement;
  }

  sendMessage() {
    this.message.date = dayjs();
    if (this.message.content != "") {
      this.messages.push({ ...this.message });
    }

    this.message.content = "";
  }

  beforeDestroy() {
    this.chatTextArea.removeEventListener("input", this.adjustTextAreaHeight);
  }

  adjustTextAreaHeight() {
    this.chatTextArea.style.height = "auto"; // Réinitialise la hauteur
    this.chatTextArea.style.height = `${Math.min(this.chatTextArea.scrollHeight, 120)}px`; // Ajuste la hauteur
  }

  scrollToBottom() {
    const discussionWrapper = this.$refs.discussionWrapper as HTMLElement;
    if (discussionWrapper) {
      discussionWrapper.scrollTop = discussionWrapper.scrollHeight;
    }
  }

  unReadCount() {
    return this.messages.filter((message) => message.read === false).length;
  }
  unReadMessage() {
    this.messages.map((message) => {
      if (message.read === false) {
        message.read = true;
      }
    });
  }
}
let component = Chat;
(function () {
  component = toNative(component);
})();
export default component;
</script>

<template>
  <div class="container-xl chatbox-wrapper">
    <section class="chatbox">
      <div class="header row">
        <div
          :class="`${chatboxOpen ? 'col-10' : 'col-6'} fw-semibold d-flex p-0`"
        >
          <img
            v-if="chatboxOpen"
            class="rounded-circle me-3 consultant-thumbnail"
            src="../../../assets/nimesThumb.jpg"
            alt=""
          />
          <div>
            {{ chatboxMessage }} <br />
            Shani Safraz
          </div>
        </div>
        <div
          v-if="!chatboxOpen"
          @mouseleave="chatboxMessage = 'Contacter'"
          class="col-6 d-flex justify-content-around"
        >
          <i
            @mouseover="chatboxMessage = 'Chatter avec'"
            @click="chatboxOpen = true"
            title="Ouvrir le chat"
            :class="`bi bi-chat-left-dots cursor-pointer align-items-center text-white text-3xl position-relative ${unReadCount() > 0 ? 'unRead-animated' : ''}`"
            ><span class="unReadNotify" v-if="unReadCount() > 0">{{
              unReadCount()
            }}</span></i
          >
          <i
            @mouseover="chatboxMessage = 'Appeler'"
            class="bi bi-telephone cursor-pointer align-items-center text-white text-3xl"
          ></i>

          <i
            @mouseover="chatboxMessage = 'Ecrire un mail à'"
            class="bi bi-envelope cursor-pointer align-items-center text-white text-3xl"
          ></i>
        </div>
        <div
          v-else
          :class="`${chatboxOpen ? 'col-2' : 'col-6'} d-flex justify-content-end p-0`"
        >
          <i
            @click="chatboxOpen = false"
            title="Fermer le chat"
            class="bi bi-dash cursor-pointer text-white text-3xl"
          ></i>
        </div>
      </div>
      <div :class="`body ${chatboxOpen ? 'body-open' : ''}`">
        <div ref="discussionWrapper" class="discussion d-flex flex-column">
          <div v-for="(message, index) in messages">
            <p
              :class="`mt-3 position-relative message w-75 py-1 px-2 rounded-3 text-gray-400 ${message.sender ? 'sended-message' : 'received-message'}`"
            >
              <span class="message-date">
                {{ message.date.format("L LT") }}
              </span>
              {{ message.content }}
            </p>
          </div>
        </div>
        <hr />
        <div class="d-flex py-3 px-1">
          <textarea
            ref="chatTextArea"
            placeholder="Ecrivez votre message..."
            v-model="message.content"
            class="w-75 py-1 px-2"
          ></textarea>
          <div class="w-25 d-flex justify-content-center">
            <button
              @click="sendMessage()"
              class="submit-message bg-primary border-0 rounded-3 align-self-end"
            >
              <i class="bi bi-send text-white"></i>
            </button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style lang="scss" scoped>
@import "@/styles/_variables.scss";

.chatbox-wrapper {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 2000;
}
.chatbox {
  margin-left: auto;
  width: 330px;
  font-size: 16px;
  box-shadow: 0px 0px 20px #00000042;
  overflow: hidden;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: white;

  .header {
    width: 100%;
    font-size: 1_px;
    margin: 0 auto;
    padding: 10px 20px;
    background: transparent linear-gradient(180deg, #da1d52 0%, #ea7a9a 100%) 0%
      0% no-repeat padding-box;
  }

  .unRead-animated {
    animation: rotateEffect 2s infinite ease-in-out;
  }

  @keyframes rotateEffect {
    0% {
      transform: rotate(0deg);
    }
    10% {
      transform: rotate(15deg);
    }
    20% {
      transform: rotate(-15deg);
    }
    30% {
      transform: rotate(15deg);
    }
    40% {
      transform: rotate(-15deg);
    }
    50% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }

  .unReadNotify {
    background-color: red;
    position: absolute;
    font-size: 16px;
    line-height: 16px;
    height: 20px;
    width: 20px;
    text-align: center;
    border-radius: 50px;
    right: -30%;
  }

  .body {
    height: 0px;
    background-color: white;
    display: flex;
    flex-direction: column;
    transition: all 0.2s ease-in-out;
    hr {
      height: 3px;
      background: black;
      width: 90%;
      margin: 0 auto;
    }
  }
  .body-open {
    height: 400px;
    transition: all 0.2s ease-in-out;
  }
}

.discussion {
  flex: 1; /* Prend toute la hauteur disponible */
  overflow-y: auto; /* Ajoute un défilement si le contenu dépasse */
  padding: 10px;
}

.message {
  position: relative;
}

.message-date {
  position: absolute;
  top: 0;
  right: 10px;
  transform: translate(0, -100%);
  font-size: 12px;
  color: $gray-300;
}

.submit-message {
  width: 35px;
  height: 35px;
}

.sended-message {
  background-color: rgba($primary, 0.1);
  margin-left: auto;
}
.sended-message:after {
  content: "";
  clip-path: polygon(0 0, 100% 100%, 100% 0);
  background-color: rgba($primary, 0.1);
  height: 10px;
  width: 10px;
  position: absolute;
  z-index: 9999;
  bottom: 0%;
  transform: translate(0, 100%);
  right: 20px;
}

.received-message {
  background-color: $gray-200;
}

.received-message:after {
  content: "";
  clip-path: polygon(0 0, 0 100%, 100% 0);
  background-color: $gray-200;
  height: 10px;
  width: 10px;
  position: absolute;
  z-index: 9999;
  bottom: 0%;
  transform: translate(0, 100%);
  left: 20px;
}

textarea {
  border: none;
  border-radius: 10px;
  flex: 1; /* Prend toute la largeur disponible */
  resize: none; /* Empêche le redimensionnement manuel */
  max-height: 120px; /* Hauteur maximale pour 6 lignes */
  overflow-y: scroll;
  resize: none;
}

.consultant-thumbnail {
  border: 2px solid white;
  width: 60px;
  height: 60px;
  //
  //@media (min-width: 768px) {
  //  border: 12px solid white;
  //  width: 125px;
  //}
  //
  //@media (min-width: 992px) {
  //  border: 2px solid white;
  //  width: 155px;
  //}
}
</style>
