import {Any, JsonObject, JsonProperty, PropertyConvertingMode} from "json2typescript";


@JsonObject("Calendar")
export default class Calendar {

	@JsonProperty("id", Number)
	id: number = undefined

	@JsonProperty("name", String)
	name: string = ''

	@JsonProperty("color", String)
	color: string = ''

	@JsonProperty("public", Boolean)
	public: boolean = undefined


    @JsonProperty("isReadable", Boolean)
    isReadable: boolean = undefined;

    @JsonProperty("isUpdatable", Boolean)
    isUpdatable: boolean = undefined;

    @JsonProperty("isDeletable", Boolean)
    isDeletable: boolean = undefined;
}
