import {Any, JsonObject, JsonProperty, PropertyConvertingMode} from "json2typescript";


@JsonObject("JobHistory")
export default class JobHistory {

	@JsonProperty("realSociete", String)
	readonly realSociete: string = ''

	@JsonProperty("idSociete", Number)
	readonly idSociete: number = undefined

	@JsonProperty("durationYear", Number)
	readonly durationYear: number = undefined

	@JsonProperty("realMetier", String)
	readonly realMetier: string = ''

	@JsonProperty("realLocation", String)
	readonly realLocation: string = ''

	@JsonProperty("isSocieteLink", Boolean)
	readonly isSocieteLink: boolean = undefined


    @JsonProperty("isReadable", Boolean)
    isReadable: boolean = undefined;

    @JsonProperty("isUpdatable", Boolean)
    isUpdatable: boolean = undefined;

    @JsonProperty("isDeletable", Boolean)
    isDeletable: boolean = undefined;
}
