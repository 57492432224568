import {Any, JsonObject, JsonProperty, PropertyConvertingMode} from "json2typescript";

import FrontOffice from "./FrontOffice";

@JsonObject("CabinetFrontOffice")
export default class CabinetFrontOffice {

	@JsonProperty("idCabFront", Number)
	idCabFront: number = undefined

	@JsonProperty("frontOffice", FrontOffice, PropertyConvertingMode.PASS_NULLABLE)
	frontOffice?: FrontOffice = undefined

	@JsonProperty("isDefault", Boolean)
	isDefault: boolean = undefined

	@JsonProperty("isPublished", Boolean)
	isPublished: boolean = undefined

	@JsonProperty("level", Number)
	level: number = undefined


    @JsonProperty("isReadable", Boolean)
    isReadable: boolean = undefined;

    @JsonProperty("isUpdatable", Boolean)
    isUpdatable: boolean = undefined;

    @JsonProperty("isDeletable", Boolean)
    isDeletable: boolean = undefined;
}
