import {Any, JsonObject, JsonProperty, PropertyConvertingMode} from "json2typescript";

import TagGlobal from "./TagGlobal";

@JsonObject("AttachmentMail")
export default class AttachmentMail {

	@JsonProperty("isIncludedMail", Boolean)
	isIncludedMail: boolean = undefined

	@JsonProperty("idAttachment", Number)
	idAttachment: number = undefined

	@JsonProperty("name", String)
	name: string = ''

	@JsonProperty("location", String)
	location: string = ''

	@JsonProperty("crc", String)
	crc: string = ''

	@JsonProperty("dateModification", String)
	dateModification: string = ''

	@JsonProperty("typeAttachment", TagGlobal)
	typeAttachment: TagGlobal = undefined

	@JsonProperty("label", String)
	readonly label: string = ''


    @JsonProperty("isReadable", Boolean)
    isReadable: boolean = undefined;

    @JsonProperty("isUpdatable", Boolean)
    isUpdatable: boolean = undefined;

    @JsonProperty("isDeletable", Boolean)
    isDeletable: boolean = undefined;
}
