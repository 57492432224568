import {Any, JsonObject, JsonProperty, PropertyConvertingMode} from "json2typescript";


@JsonObject("TypeAction")
export default class TypeAction {


    @JsonProperty("isReadable", Boolean)
    isReadable: boolean = undefined;

    @JsonProperty("isUpdatable", Boolean)
    isUpdatable: boolean = undefined;

    @JsonProperty("isDeletable", Boolean)
    isDeletable: boolean = undefined;
}
