import {Any, JsonObject, JsonProperty, PropertyConvertingMode} from "json2typescript";

import JobHistory from "./JobHistory";
import Contact from "./Contact";
import Consultant from "./Consultant";
import TagGlobal from "./TagGlobal";

@JsonObject("JobReference")
export default class JobReference {

	@JsonProperty("idReference", Number)
	idReference: number = undefined

	@JsonProperty("jobHistory", JobHistory, PropertyConvertingMode.PASS_NULLABLE)
	jobHistory?: JobHistory = undefined

	@JsonProperty("contact", Contact, PropertyConvertingMode.PASS_NULLABLE)
	contact?: Contact = undefined

	@JsonProperty("consultantCreator", Consultant, PropertyConvertingMode.PASS_NULLABLE)
	consultantCreator?: Consultant = undefined

	@JsonProperty("createAt", String)
	createAt: string = ''

	@JsonProperty("comment", String)
	comment: string = ''

	@JsonProperty("nomSociete", String)
	nomSociete: string = ''

	@JsonProperty("nom", String)
	nom: string = ''

	@JsonProperty("fonction", String)
	fonction: string = ''

	@JsonProperty("email", String)
	email: string = ''

	@JsonProperty("telephone", String)
	telephone: string = ''

	@JsonProperty("reason", TagGlobal, PropertyConvertingMode.PASS_NULLABLE)
	reason?: TagGlobal = undefined

	@JsonProperty("terminated", Boolean)
	terminated: boolean = undefined

	@JsonProperty("shared", Boolean)
	shared: boolean = undefined

	@JsonProperty("idContact", Number)
	readonly idContact: number = undefined


    @JsonProperty("isReadable", Boolean)
    isReadable: boolean = undefined;

    @JsonProperty("isUpdatable", Boolean)
    isUpdatable: boolean = undefined;

    @JsonProperty("isDeletable", Boolean)
    isDeletable: boolean = undefined;
}
