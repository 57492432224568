import {Any, JsonObject, JsonProperty, PropertyConvertingMode} from "json2typescript";

import Consultant from "./Consultant";
import TagGlobal from "./TagGlobal";

@JsonObject("CommissionPlacement")
export default class CommissionPlacement {

	@JsonProperty("idCommission", Number)
	idCommission: number = undefined

	@JsonProperty("consult", Consultant, PropertyConvertingMode.PASS_NULLABLE)
	consult?: Consultant = undefined

	@JsonProperty("origineCommission", TagGlobal)
	origineCommission: TagGlobal = undefined


    @JsonProperty("isReadable", Boolean)
    isReadable: boolean = undefined;

    @JsonProperty("isUpdatable", Boolean)
    isUpdatable: boolean = undefined;

    @JsonProperty("isDeletable", Boolean)
    isDeletable: boolean = undefined;
}
