import {Any, JsonObject, JsonProperty, PropertyConvertingMode} from "json2typescript";


@JsonObject("PublicationProvider")
export default class PublicationProvider {

	@JsonProperty("idProvider", Number)
	idProvider: number = undefined

	@JsonProperty("name", String)
	name: string = ''

	@JsonProperty("className", String)
	className: string = ''

	@JsonProperty("asynchronous", Boolean)
	asynchronous: boolean = undefined

	@JsonProperty("withIframe", Boolean)
	withIframe: boolean = undefined

	@JsonProperty("useCredential", Boolean)
	useCredential: boolean = undefined

}
