import {Any, JsonObject, JsonProperty, PropertyConvertingMode} from "json2typescript";

import Cabinet from "./Cabinet";

@JsonObject("Groupe")
export default class Groupe {

	@JsonProperty("idGroupe", Number)
	idGroupe: number = undefined

	@JsonProperty("cabinet", Cabinet)
	cabinet: Cabinet = undefined

	@JsonProperty("nom", String)
	nom: string = ''

	@JsonProperty("adChase", Boolean)
	adChase: boolean = undefined

	@JsonProperty("priorityPush", Boolean)
	priorityPush: boolean = undefined


    @JsonProperty("isReadable", Boolean)
    isReadable: boolean = undefined;

    @JsonProperty("isUpdatable", Boolean)
    isUpdatable: boolean = undefined;

    @JsonProperty("isDeletable", Boolean)
    isDeletable: boolean = undefined;
}
