import {JsonConverter, JsonCustomConvert} from "json2typescript";
import  moment, {Moment} from "moment";
@JsonConverter
export default class MomentConverter implements JsonCustomConvert<Moment|null> {
    serialize(date: Moment|null): string|null {
        return date ? date.format(): null;
    }

    deserialize(date: string|null): Moment|null {
        if( date === undefined) {
            throw "date is invalid" + date;
            return null;
        }
        if( date === null) {
            return null;
        }
        const m = moment(date);
        if(!m.isValid()){
            throw "date is invalid" + date;
        }
        return m;
    }
}
