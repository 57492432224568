import {Any, JsonObject, JsonProperty, PropertyConvertingMode} from "json2typescript";

import Candidat from "./Candidat";
import Mission from "./Mission";
import TagGlobal from "./TagGlobal";
import Consultant from "./Consultant";
import DateTimeImmutable from "./DateTimeImmutable";
import AttachmentCandidat from "./AttachmentCandidat";
import CandidatureContact from "./CandidatureContact";
import CvClientSharedParameters from "./CvClientSharedParameters";

@JsonObject("Candidature")
export default class Candidature {

	@JsonProperty("idCandidature", Number)
	idCandidature: number = undefined

	@JsonProperty("candidat", Candidat, PropertyConvertingMode.PASS_NULLABLE)
	candidat?: Candidat = undefined

	@JsonProperty("mission", Mission, PropertyConvertingMode.PASS_NULLABLE)
	mission?: Mission = undefined

	@JsonProperty("creationAt", String)
	creationAt: string = ''

	@JsonProperty("modificationAt", String)
	modificationAt: string = ''

	@JsonProperty("newTarget", Boolean)
	newTarget: boolean = undefined

	@JsonProperty("workflow", [String])
	workflow: string[] = undefined

	@JsonProperty("lastAction", String)
	lastAction: string = ''

	@JsonProperty("status", String)
	status: string = ''

	@JsonProperty("priority", Number)
	priority: number = undefined

	@JsonProperty("source", TagGlobal, PropertyConvertingMode.PASS_NULLABLE)
	source?: TagGlobal = undefined

	@JsonProperty("consultantConf", Consultant, PropertyConvertingMode.PASS_NULLABLE)
	consultantConf?: Consultant = undefined

	@JsonProperty("origine", String)
	origine: string = ''

	@JsonProperty("nbSuiviInteClient", Number)
	nbSuiviInteClient: number = undefined

	@JsonProperty("nextSuiviIntegrationAt", String)
	nextSuiviIntegrationAt: string = ''

	@JsonProperty("remunerationAsked", Number)
	remunerationAsked: number = undefined

	@JsonProperty("clientShared", Boolean)
	clientShared: boolean = undefined

	@JsonProperty("sharedAt", DateTimeImmutable)
	sharedAt: DateTimeImmutable = undefined

	@JsonProperty("attachmentShared", AttachmentCandidat, PropertyConvertingMode.PASS_NULLABLE)
	attachmentShared?: AttachmentCandidat = undefined

	@JsonProperty("oneContactInteresting", Boolean)
	oneContactInteresting: boolean = undefined

	@JsonProperty("oneContactReadAt", String)
	oneContactReadAt: string = ''

	@JsonProperty("resume", String)
	resume: string = ''

	@JsonProperty("parcours", String)
	parcours: string = ''

	@JsonProperty("presentation", String)
	presentation: string = ''

	@JsonProperty("remuneration", String)
	remuneration: string = ''

	@JsonProperty("motivation", String)
	motivation: string = ''

	@JsonProperty("disponibilite", String)
	disponibilite: string = ''

	@JsonProperty("shareFiles", [AttachmentCandidat], PropertyConvertingMode.PASS_NULLABLE)
	shareFiles?: AttachmentCandidat[] = undefined

	@JsonProperty("currentContact", CandidatureContact, PropertyConvertingMode.PASS_NULLABLE)
	currentContact?: CandidatureContact = undefined

	@JsonProperty("cvSharedParameters", CvClientSharedParameters, PropertyConvertingMode.PASS_NULLABLE)
	cvSharedParameters?: CvClientSharedParameters = undefined

	@JsonProperty("isRead", Any)
	readonly isRead: any = undefined

	@JsonProperty("nbSuiviIntegration", Number, PropertyConvertingMode.PASS_NULLABLE)
	readonly nbSuiviIntegration?: number = undefined

	@JsonProperty("isAvailable", Boolean)
	readonly isAvailable: boolean = undefined

	@JsonProperty("isCandidatAvailable", Boolean)
	readonly isCandidatAvailable: boolean = undefined


    @JsonProperty("isReadable", Boolean)
    isReadable: boolean = undefined;

    @JsonProperty("isUpdatable", Boolean)
    isUpdatable: boolean = undefined;

    @JsonProperty("isDeletable", Boolean)
    isDeletable: boolean = undefined;
}
