import {Any, JsonObject, JsonProperty, PropertyConvertingMode} from "json2typescript";


@JsonObject("CvClientSharedParameters")
export default class CvClientSharedParameters {

	@JsonProperty("shareCvFile", Boolean)
	shareCvFile: boolean = undefined

	@JsonProperty("shareEmail", Boolean)
	shareEmail: boolean = undefined

	@JsonProperty("sharePhone", Boolean)
	sharePhone: boolean = undefined

	@JsonProperty("shareAddress", Boolean)
	shareAddress: boolean = undefined

	@JsonProperty("shareFormationLevel", Boolean)
	shareFormationLevel: boolean = undefined

	@JsonProperty("shareEnglishLevel", Boolean)
	shareEnglishLevel: boolean = undefined

	@JsonProperty("sharePreavis", Boolean)
	sharePreavis: boolean = undefined

	@JsonProperty("shareVehicule", Boolean)
	shareVehicule: boolean = undefined

	@JsonProperty("shareExperience", Boolean)
	shareExperience: boolean = undefined

	@JsonProperty("shareRemuneration", Boolean)
	shareRemuneration: boolean = undefined

	@JsonProperty("shareEncadrement", Boolean)
	shareEncadrement: boolean = undefined

	@JsonProperty("shareTest", Boolean)
	shareTest: boolean = undefined

	@JsonProperty("shareLogiciels", Boolean)
	shareLogiciels: boolean = undefined

	@JsonProperty("sharePresentation", Boolean)
	sharePresentation: boolean = undefined

	@JsonProperty("shareParcours", Boolean)
	shareParcours: boolean = undefined

	@JsonProperty("shareMotivation", Boolean)
	shareMotivation: boolean = undefined

	@JsonProperty("shareDisponibilite", Boolean)
	shareDisponibilite: boolean = undefined

	@JsonProperty("sharePriseRef", Boolean)
	sharePriseRef: boolean = undefined

	@JsonProperty("shareCvFirst", Boolean)
	shareCvFirst: boolean = undefined

}
