<script lang="ts">
import { Vue, Component, toNative } from "vue-facing-decorator";
import { b } from "vite/dist/node/types.d-aGj9QkWt";

@Component({
  components: {
    Navbar: () => import("@/components/layouts/navigation/Navbar.vue"),
    NavbarSub: () => import("@/components/layouts/navigation/NavbarSub.vue"),
    Footer: () => import("@/components/layouts/navigation/Footer.vue"),
    SelectRoles: () => import("@/components/tmp/selectRoles.vue"),
    MobileFooter: () =>
      import("@/components/layouts/navigation/MobileFooter.vue"),
    Chat: () => import("@/components/generic/chat/Chat.vue"),
  },
})
class AuthenticatedLayout extends Vue {
  windowTop: number = 0;
  hideNav: boolean = false;
  mobileMenuOpen: boolean = false;
  mounted() {
    window.addEventListener("scroll", this.onScroll);
    window.addEventListener("resize", this.checkScreenWidth);
  }
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
    window.removeEventListener("resize", this.checkScreenWidth);
  }

  onScroll(e) {
    if (window.top && !this.mobileMenuOpen) {
      this.hideNav =
        window.top.scrollY > 159 && window.top.scrollY >= this.windowTop;
      this.windowTop = window.top.scrollY;
    }
  }

  onToggleMenuMobile(expand: boolean) {
    this.mobileMenuOpen = expand;
  }

  checkScreenWidth() {
    // Automatic close the mobile menu if screen larger than 573
    if (window.innerWidth > 573) {
      this.mobileMenuOpen = false;
    }
  }
}
let component = AuthenticatedLayout;
(function () {
  component = toNative(component);
})();
export default component;
</script>

<template>
  <div class="">
    <div :class="`navbar-wrap ${hideNav ? 'hide-nav' : ''}`">
      <Navbar></Navbar>
      <NavbarSub @toggle-mobile-menu="onToggleMenuMobile"></NavbarSub>
    </div>
    <div :class="`content-wrap`">
      <router-view />
      <Chat />
    </div>
    <SelectRoles />
    <Footer :class="'d-none d-md-flex'"></Footer>
    <MobileFooter :class="'d-md-none'"></MobileFooter>
  </div>
</template>

<style>
body {
  padding-top: 159px;
}
.navbar-wrap {
  height: 159px;
}

@media (max-width: 1199px) {
  body {
    padding-top: 153px;
  }

  .navbar-wrap {
    height: 153px;
  }
}

@media (max-width: 992px) {
  body {
    padding-top: 150px;
  }

  .navbar-wrap {
    height: 150px;
  }
}

@media (max-width: 769px) {
  body {
    padding-top: 136px;
  }

  .navbar-wrap {
    height: 136px;
  }
}

@media (max-width: 573px) {
  body {
    padding-top: 141px;
  }

  .navbar-wrap {
    height: 141px;
  }
}
</style>
