import {Any, JsonObject, JsonProperty, PropertyConvertingMode} from "json2typescript";

import Societe from "./Societe";
import Cabinet from "./Cabinet";
import Consultant from "./Consultant";
import TypeAction from "./TypeAction";
import AbstractAdChase from "./AbstractAdChase";
import AffaireContact from "./AffaireContact";
import AffaireBesoin from "./AffaireBesoin";

@JsonObject("Affaire")
export default class Affaire {

	@JsonProperty("idAffaire", Number)
	idAffaire: number = undefined

	@JsonProperty("societe", Societe, PropertyConvertingMode.PASS_NULLABLE)
	societe?: Societe = undefined

	@JsonProperty("cabinet", Cabinet)
	cabinet: Cabinet = undefined

	@JsonProperty("consultant", Consultant, PropertyConvertingMode.PASS_NULLABLE)
	consultant?: Consultant = undefined

	@JsonProperty("creationDate", String)
	creationDate: string = ''

	@JsonProperty("changeStatusDate", String)
	changeStatusDate: string = ''

	@JsonProperty("status", String)
	status: string = ''

	@JsonProperty("origine", String)
	origine: string = ''

	@JsonProperty("workflow", [String])
	workflow: string[] = undefined

	@JsonProperty("lastAction", TypeAction)
	lastAction: TypeAction = undefined

	@JsonProperty("lastWorkflowDate", String)
	lastWorkflowDate: string = ''

	@JsonProperty("creator", Consultant, PropertyConvertingMode.PASS_NULLABLE)
	creator?: Consultant = undefined

	@JsonProperty("adChase", AbstractAdChase, PropertyConvertingMode.PASS_NULLABLE)
	adChase?: AbstractAdChase = undefined

	@JsonProperty("parentAffaire", Affaire, PropertyConvertingMode.PASS_NULLABLE)
	parentAffaire?: Affaire = undefined

	@JsonProperty("priority", Number)
	priority: number = undefined

	@JsonProperty("archived", Boolean)
	archived: boolean = undefined

	@JsonProperty("contactLinks", [AffaireContact], PropertyConvertingMode.PASS_NULLABLE)
	contactLinks?: AffaireContact[] = undefined

	@JsonProperty("consultBackups", [Consultant], PropertyConvertingMode.PASS_NULLABLE)
	consultBackups?: Consultant[] = undefined

	@JsonProperty("besoins", [AffaireBesoin], PropertyConvertingMode.PASS_NULLABLE)
	besoins?: AffaireBesoin[] = undefined

	@JsonProperty("lastStatus", String)
	readonly lastStatus: string = ''

	@JsonProperty("isRollbackable", Boolean)
	readonly isRollbackable: boolean = undefined


    @JsonProperty("isReadable", Boolean)
    isReadable: boolean = undefined;

    @JsonProperty("isUpdatable", Boolean)
    isUpdatable: boolean = undefined;

    @JsonProperty("isDeletable", Boolean)
    isDeletable: boolean = undefined;
}
