import {Any, JsonObject, JsonProperty, PropertyConvertingMode} from "json2typescript";

import TagGlobal from "./TagGlobal";
import ConsultantAvatar from "./ConsultantAvatar";

@JsonObject("Consultant")
export default class Consultant {

	@JsonProperty("idConsult", Number)
	idConsult: number = undefined

	@JsonProperty("login", String)
	login: string = ''

	@JsonProperty("admin", Boolean)
	admin: boolean = undefined

	@JsonProperty("flagManager", Boolean)
	flagManager: boolean = undefined

	@JsonProperty("nom", String)
	nom: string = ''

	@JsonProperty("fonction", TagGlobal, PropertyConvertingMode.PASS_NULLABLE)
	fonction?: TagGlobal = undefined

	@JsonProperty("avatar", ConsultantAvatar, PropertyConvertingMode.PASS_NULLABLE)
	avatar?: ConsultantAvatar = undefined

	@JsonProperty("telephone", String)
	telephone: string = ''

	@JsonProperty("mobile", String)
	mobile: string = ''

	@JsonProperty("phoneService", String)
	phoneService: string = ''

	@JsonProperty("msGraphPrincipalName", String)
	msGraphPrincipalName: string = ''

	@JsonProperty("msGraphCalendarId", String)
	msGraphCalendarId: string = ''

	@JsonProperty("preferedPhoneNumber", String)
	preferedPhoneNumber: string = ''

	@JsonProperty("language", String)
	language: string = ''

	@JsonProperty("email", String, PropertyConvertingMode.PASS_NULLABLE)
	email?: string = undefined

	@JsonProperty("manager", Consultant, PropertyConvertingMode.PASS_NULLABLE)
	manager?: Consultant = undefined

	@JsonProperty("mobileRaw", String, PropertyConvertingMode.PASS_NULLABLE)
	mobileRaw?: string = undefined

	@JsonProperty("telephoneRaw", String, PropertyConvertingMode.PASS_NULLABLE)
	telephoneRaw?: string = undefined

	@JsonProperty("mainProfile", Any, PropertyConvertingMode.PASS_NULLABLE)
	readonly mainProfile?: any = undefined

	@JsonProperty("groupeAffectations", Any, PropertyConvertingMode.PASS_NULLABLE)
	readonly groupeAffectations?: any = undefined

	@JsonProperty("bgColor", String)
	readonly bgColor: string = ''


    @JsonProperty("isReadable", Boolean)
    isReadable: boolean = undefined;

    @JsonProperty("isUpdatable", Boolean)
    isUpdatable: boolean = undefined;

    @JsonProperty("isDeletable", Boolean)
    isDeletable: boolean = undefined;
}
