import {Any, JsonObject, JsonProperty, PropertyConvertingMode} from "json2typescript";

import Contact from "./Contact";

@JsonObject("CandidatureContact")
export default class CandidatureContact {

	@JsonProperty("contact", Contact, PropertyConvertingMode.PASS_NULLABLE)
	contact?: Contact = undefined

	@JsonProperty("readAt", String)
	readAt: string = ''

	@JsonProperty("downloadAt", String)
	downloadAt: string = ''

	@JsonProperty("interesting", Boolean)
	interesting: boolean = undefined

	@JsonProperty("interestComment", String)
	interestComment: string = ''

}
