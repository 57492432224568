import {Any, JsonObject, JsonProperty, PropertyConvertingMode} from "json2typescript";


@JsonObject("FrontOffice")
export default class FrontOffice {

	@JsonProperty("code", String)
	code: string = ''

	@JsonProperty("name", String)
	name: string = ''

	@JsonProperty("url", String)
	url: string = ''


    @JsonProperty("isReadable", Boolean)
    isReadable: boolean = undefined;

    @JsonProperty("isUpdatable", Boolean)
    isUpdatable: boolean = undefined;

    @JsonProperty("isDeletable", Boolean)
    isDeletable: boolean = undefined;
}
