<script lang="ts">
import { Vue, Component, toNative } from "vue-facing-decorator";

@Component({
  components: {
    Image: () => import("@/components/images/Image.vue"),
  },
})
class Footer extends Vue {}
let component = Footer;
(function () {
  component = toNative(component);
})();
export default component;
</script>

<template>
  <footer class="">
    <div class="container py-4 px-4">
      <div class="d-flex justify-content-between">
        <Image :class="'lkt-logo'" :src="'lkt-logo.png'" />
        <div class="flex align-content-center">
          <a href="#">
            <i class="text-3xl bi-linkedin"></i>
          </a>
          <a href="#" class="mx-3">
            <i class="text-3xl bi-spotify"></i>
          </a>
          <a href="#">
            <i class="text-3xl bi-youtube"></i>
          </a>
        </div>
      </div>
    </div>
    <div class="footer-bg pt-10 pb-10 px-4">
      <div class="container p-0">
        <div class="w-100">
          <h6 class="text-secondary text-md fw-bold mb-3">
            {{ $t("title.ourContactDetails") }}
          </h6>
          <div class="text-gray-400">
            <p class="pb-0 mb-1">{{ $t("word.tel") }}: 01 80 88 61 02</p>
            <p class="pb-0 mb-1">
              {{ $t("word.mail") }}: contact@linkingtalents.fr
            </p>
            <p class="pb-0 mb-1">
              {{ $t("word.adress") }}: 78 avenue des Champs Élysées B562 - 75008
              Paris
            </p>
          </div>
        </div>
        <div class="w-100">
          <article class="pt-5">
            <i
              class="lkt-icon-1fortheplanet icon-w-6 icon-h-3 text-gray-300"
            ></i>
            <p class="text-small text-gray-400">
              {{ $t("footer.1percent") }}
            </p>
          </article>
        </div>
        <p class="text-gray-400 text-sm mt-3 mb-0">
          © LinkingTalents.fr 2024 <br />
          {{ $t("footer.allRightsReserved") }}
        </p>
      </div>
    </div>
  </footer>
</template>

<style scoped>
footer {
  width: 100%;
  position: absolute;
  bottom: 0;

  @media (max-width: 768px) {
    height: 609px;
  }
}

.footer-bg {
  background: transparent
    linear-gradient(180deg, hsla(240, 1%, 66%, 0.3), hsla(0, 0%, 89%, 0.3)) 0 0
    no-repeat padding-box;
}

footer .lkt-logo {
  max-height: 65px;
}
</style>
