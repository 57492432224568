import {Any, JsonObject, JsonProperty, PropertyConvertingMode} from "json2typescript";


@JsonObject("AnnonceJobBoard")
export default class AnnonceJobBoard {

	@JsonProperty("idAnnonceJobBoard", Number)
	idAnnonceJobBoard: number = undefined

	@JsonProperty("name", String)
	name: string = ''

	@JsonProperty("externalUrl", String)
	externalUrl: string = ''

	@JsonProperty("dateModification", String)
	dateModification: string = ''

	@JsonProperty("dateCreation", String)
	dateCreation: string = ''

	@JsonProperty("status", String)
	status: string = ''

	@JsonProperty("additionalInfo", String)
	additionalInfo: string = ''

	@JsonProperty("providerName", String)
	readonly providerName: string = ''


    @JsonProperty("isReadable", Boolean)
    isReadable: boolean = undefined;

    @JsonProperty("isUpdatable", Boolean)
    isUpdatable: boolean = undefined;

    @JsonProperty("isDeletable", Boolean)
    isDeletable: boolean = undefined;
}
