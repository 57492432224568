<script lang="ts">
import { Vue, Component, toNative } from "vue-facing-decorator";
import { usePermissionsStore } from "@/store/PermissionsStore";
import { useUserStore } from "@/store/UserStore";

@Component({
  methods: { useUserStore, usePermissionsStore },
})
class SelectRoles extends Vue {
  selectedRole: string = "ADMIN";
  isVisible: boolean = true;
  langs: string[] = ["fr-FR", "en-US"];
  usePermissionsStore = usePermissionsStore;
  useUserStore = useUserStore;
  updateRole() {
    usePermissionsStore().setRoles([this.selectedRole]);
  }

  toggle() {
    this.isVisible = !this.isVisible;
  }
}

let component = SelectRoles;
(function () {
  component = toNative(component);
})();
export default component;
</script>

<template>
  <div
    class="z-3 shadow bg-white p-4 select-roles"
    :style="{ right: isVisible ? '-190px' : '0' }"
  >
    <button
      @click="toggle"
      class="toggle-select-roles btn btn-primary"
    ></button>
    <div class="lkt-form-group">
      <label for="select-2">Changer de rôle</label>
      <select
        v-model="selectedRole"
        @change="updateRole"
        id="select-2"
        required
      >
        <option value="ADMIN">ADMIN</option>
        <option value="EDITOR">EDITOR</option>
      </select>
    </div>
    <p class="text-sm text-gray-400 mt-3">
      Rôles : {{ usePermissionsStore().roles }}
    </p>
    <div class="locale-changer">
      <select v-model="$i18n.locale">
        <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">
          {{ lang }}
        </option>
      </select>
      Lang : {{ $i18n.locale }}
    </div>
  </div>
</template>

<style scoped>
.select-roles {
  position: fixed;
  top: 50%;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  min-width: 210px;
  right: 0;
  transition: all 0.2s ease-in-out;
}

.toggle-select-roles {
  position: absolute;
  left: 4px;
  height: 100px;
  width: 5px;
  padding: 0;
}
.toggle-select-roles:hover {
  background-color: #9a0e35;
}
</style>
