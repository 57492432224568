interface Cv {
  idCandidature: number;
  fileName: string;
  format: string;
}

export const cvMock: Cv[] = [
  {
    idCandidature: 1423807,
    fileName: "moisant_cv.pdf",
    format: "pdf",
  },
  {
    idCandidature: 253807,
    fileName: "moisant_cv.pdf",
    format: "pdf",
  },
  {
    idCandidature: 689807,
    fileName: "moisant_cv.pdf",
    format: "pdf",
  },
];
